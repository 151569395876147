import { FC, ReactNode, useMemo } from 'react'
import {
  Album, AlbumPage, AlbumPageType, ImageSize,
} from '@api/gql/graphql'
import {
  AlbumBackCover,
  AlbumCover,
  AlbumPicture,
  PageLayout,
} from '@share/album'
import { clsx } from 'clsx'

import { PageItemSort } from './page-item-sort'

type Props = {
  album: Album;
  page: AlbumPage;
  spreadIndex: number;
  pageLabel?: ReactNode
  isLeft?: boolean;
  isRight?: boolean;
  isWide?: boolean;
  isDivider?: boolean;
  isControl?: boolean;
  isSpread?: boolean;
  isSortable?: boolean;
  onClick?: () => void;
  spreadPageTypes?: AlbumPageType[];
  pageLayoutClassName?: string
};

export const PageItem: FC<Props> = (props) => {
  const isLabeled = [
    AlbumPageType.Picture,
    AlbumPageType.Text,
    AlbumPageType.Additional,
    AlbumPageType.BackCover,
  ].includes(props.page.type as AlbumPageType)

  const isSpreadTypesSortable = useMemo(() => {
    return props.spreadPageTypes?.filter(
      (pageType) => pageType === AlbumPageType.Picture || pageType === AlbumPageType.Text,
    ).length === props.spreadPageTypes?.length
  }, [props.spreadPageTypes, props.isSpread])

  return (
    <div
      className="relative mb-8 md:mb-0"
      data-id={props.page.id}
      onClick={props.onClick}
    >
      {isLabeled && props.pageLabel}

      <PageLayout
        isWide={props.isWide}
        className={clsx(
          'relative bg-gray-80 md:rounded-xl',
          'text-[0.26em] sm:text-[0.53em] md:text-[0.6em] lg:text-[0.8em]',
          props.isLeft && 'rounded-l-md',
          props.isRight && 'rounded-r-md',
          props.isLeft && props.isDivider && 'border-r-3 border-gray-200 md:border-0',
          props.pageLayoutClassName,
        )}
      >
        {props.page.type === AlbumPageType.FrontCover && (
          <AlbumCover album={props.album} />
        )}

        {props.page.type === AlbumPageType.Picture
          && props.page?.images?.map((image) => (
            <AlbumPicture
              key={image.external_id}
              albumId={props.album.id}
              image={image}
              isControl={props.isControl}
              pageWidth={props.page.width || 0}
              pageHeight={props.page.height || 0}
              imageSize={ImageSize.Medium}
              isWide={props.isWide}
            />
          ))}

        {props.page.type === AlbumPageType.BackCover && (
          <AlbumBackCover isWide={props.isWide} />
        )}
      </PageLayout>

      {props.isSortable && isSpreadTypesSortable && !props.isSpread && (
        <PageItemSort
          albumPage={props.page}
          spreadIndex={props.spreadIndex}
          isUp={props.isLeft}
          isDown={props.isRight}
          className="mt-4 mb:mt-6"
        />
      )}
    </div>
  )
}
