import {
  FC, ReactNode, useContext, useMemo,
} from 'react'
import { useIsMobile } from '@nextui-org/use-is-mobile'
import { WindowSizesContext } from '@share/album/context'
import { clsx } from 'clsx'

type Props = {
  children: ReactNode
  className?: string
  isWide?: boolean
  dev?: boolean
}

export const PageLayout: FC<Props> = (props) => {
  const windowSizes = useContext(WindowSizesContext)
  const isMobile = useIsMobile()
  const {
    left, right, bottom, top,
  } = windowSizes.padding

  // Внешние стили страницы
  const outerStyle = useMemo(() => {
    if (isMobile) {
      return {
        padding: `${top}px ${right}px ${bottom}px ${left}px`,
        width: '100%',
      }
    }

    if (props.isWide) {
      return {
        padding: `${top}px ${right}px ${bottom}px ${left}px`,
        width: `${windowSizes.outer.width}px`,
        height: `${windowSizes.outer.height}px`,
      }
    }

    return {
      padding: `${top}px ${right}px ${bottom}px ${left}px`,
      width: `${windowSizes.outerHalf.width}px`,
      height: `${windowSizes.outerHalf.height}px`,
    }
  }, [isMobile, windowSizes])

  // Внутренние стили страницы
  const innerStyle = useMemo(() => {
    if (isMobile) {
      if (props.isWide) {
        return {
          width: `${windowSizes.inner.width}px`,
          height: `${windowSizes.inner.height}px`,
        }
      }

      return {
        width: `${windowSizes.innerHalf.width}px`,
        height: `${windowSizes.innerHalf.height}px`,
      }
    }

    if (props.isWide) {
      return {
        width: `${windowSizes.inner.width}px`,
        height: `${windowSizes.inner.height}px`,
      }
    }

    return {
      width: `${windowSizes.innerHalf.width}px`,
      height: `${windowSizes.innerHalf.height}px`,
    }
  }, [props.isWide, windowSizes, isMobile])

  return (
    <div
      style={outerStyle}
      className={clsx(
        props.dev && 'border border-orange-500',
        props.className,
        isMobile && 'aspect-square',
      )}
    >
      <div className="relative" style={innerStyle}>
        {props.children}
      </div>
    </div>
  )
}
