import { graphql } from '@api/gql/gql'

export const updateAlbum = graphql(`
    mutation updateAlbum($input: AlbumInput!) {
        update_album(input: $input) {
            id
            cover_type
            title
            subtitle
            creator_name
            from_at
            to_at
            updated_at
        }
    }
`)
