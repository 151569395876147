import { useMemo } from 'react'
import { AlbumPage, AlbumPageType } from '@api/gql/graphql'
import { albumPagingOffset } from '@constants/album'
import chunk from 'lodash/chunk'

export const VISIBLE_ALBUM_PAGE_TYPES = [
  AlbumPageType.Text,
  AlbumPageType.Picture,
  AlbumPageType.FrontCover,
]

export const usePages = (pages: AlbumPage[]) => {
  // Активные страницы
  const pagesLive = useMemo(
    () => pages
      .map((page) => ({
        ...page,
        images: page.images?.filter((image) => !image.deleted_at) ?? [],
      }))
      .filter((page) => {
        return !!page.images?.length
      })
      .sort((a, b) => (a.sort ?? 0) - (b.sort ?? 0))
      .map((page, index) => ({
        ...page,
        page_number: index - albumPagingOffset,
      })),
    [pages],
  )

  const visiblePagesLive = useMemo(
    () => pagesLive.filter((page) => VISIBLE_ALBUM_PAGE_TYPES.includes(page.type as AlbumPageType)),
    [pagesLive],
  )

  // Развороты
  const spreads = useMemo(() => chunk(pagesLive, 2).map((p) => {
    return p.filter((page) => VISIBLE_ALBUM_PAGE_TYPES.includes(page.type as AlbumPageType))
  }), [pagesLive])

  return {
    pagesLive,
    visiblePagesLive,
    spreads,
  }
}
