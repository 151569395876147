import { CoverType } from '@api/gql/graphql'

export const getCoverTypeClass = (coverType: CoverType): string => {
  switch (coverType) {
    case CoverType.Cover_5x1:
      return 'grid-cols-5'
    case CoverType.Cover_5x2:
      return 'grid-cols-5'
    case CoverType.Cover_5x3:
      return 'grid-cols-5'
    case CoverType.Cover_7x3:
      return 'grid-cols-7'
    case CoverType.Cover_8x1:
      return 'grid-cols-8 h-full'
    case CoverType.Cover_10x2:
      return 'grid-cols-10'
    default:
      return ''
  }
}

export const getCoverItemClass = (coverType: CoverType): string => {
  switch (coverType) {
    case CoverType.Cover_8x1:
      return 'w-full bg-gray-400 aspect-square h-full'
    default:
      return 'w-full bg-gray-400 aspect-square'
  }
}

export const getCoverContainerClass = (coverType: CoverType): string => {
  switch (coverType) {
    case CoverType.Cover_5x1:
    case CoverType.Cover_5x2:
    case CoverType.Cover_10x2:
      return 'justify-end'
    default:
      return ''
  }
}
