import { FC, useMemo } from 'react'
import { AlbumImage } from '@api/gql/graphql'
import { getProfile } from '@api/schemas'
import { useMutation, useQuery } from '@apollo/client'
import { toast } from '@components/toast'
import {
  faArrowsUpDownLeftRight,
  faEllipsis,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { ModalType } from '@modals/_types'
import { useModal } from '@modals/core/use-modal'
import { Button } from '@nextui-org/button'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@nextui-org/dropdown'
import { useIsMobile } from '@nextui-org/use-is-mobile'
import { deleteAlbumImage } from '@share/album/api'

type Props = {
  albumId: string
  isOpen: boolean;
  image: AlbumImage;
  editable?: boolean;
  onClose: () => void
};

export const Control: FC<Props> = (props) => {
  const isMobile = useIsMobile()
  const { openModal } = useModal()
  const profileState = useQuery(getProfile, { fetchPolicy: 'cache-only' })

  const [deleteAlbumImageMutation, deleteAlbumImageState] = useMutation(deleteAlbumImage, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  const onRemove = async () => {
    amplitude.event({
      type: AmplitudeEvent.AlbumDeleteImage,
      image_id: props.image.id,
      user_role: profileState.data?.profile.role ?? undefined,
    })

    await deleteAlbumImageMutation({
      variables: { albumImageId: props.image.id },
    })
  }

  const onMove = () => {
    openModal({
      type: ModalType.MOVE_IMAGE_MODAL,
      album_id: props.albumId,
      album_image_id: props.image.id,
    })

    props.onClose()
  }

  const items = useMemo(() => [
    {
      label: 'Move',
      icon: faArrowsUpDownLeftRight,
      onClick: onMove,
    },
    {
      label: 'Delete',
      icon: faTrash,
      onClick: onRemove,
      isLoading: deleteAlbumImageState.loading,
      isDisabled: false,
    },
  ], [deleteAlbumImageState.loading])

  const disabledItems = useMemo(() => items.filter(
    (item) => item.isDisabled,
  ).map((item) => item.label), [items])

  return (
    <>
      <Dropdown isOpen={isMobile ? props.isOpen : undefined} backdrop="blur">
        <DropdownTrigger>
          <Button
            variant="flat"
            size="sm"
            isIconOnly
            isDisabled={!props.editable}
            className="bottom-2 right-2 absolute bg-white/50 rounded-lg hover:bg-white/70"
            startContent={<FontAwesomeIcon icon={faEllipsis} className="text-gray-600" />}
          />
        </DropdownTrigger>
        <DropdownMenu
          aria-label=""
          items={items}
          disabledKeys={disabledItems}
          onAction={(key) => {
            const action = items.find((item) => item.label === key)
            if (action?.onClick) {
              action.onClick()
            }
          }}
        >
          {(item) => (
            <DropdownItem
              key={item.label}
              startContent={<FontAwesomeIcon icon={item.icon} className="text-gray-600" />}
              color="default"
            >
              {item.label}
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>

      {!isMobile && (
        <Button
          variant="flat"
          size="sm"
          isIconOnly
          isDisabled={!props.editable}
          className="bottom-12 right-2 absolute bg-white/50 rounded-lg hover:bg-white/70"
          startContent={<FontAwesomeIcon icon={faTrash} className="text-gray-600" />}
          onClick={onRemove}
        />
      )}
    </>
  )
}
