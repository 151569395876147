import { FC } from 'react'
import { SvgHeart } from '@components/icons'
import { clsx } from 'clsx'

type Props = {
  isWide?: boolean
}

export const AlbumBackCover: FC<Props> = (props) => {
  return (
    <div className="flex h-full flex-col justify-end">
      <div className={clsx(
        'md:text-[1.3em] text-gray-500 flex gap-x-1',
        props.isWide ? 'text-[3em]' : 'text-[1.5em]',
      )}
      >
        with
        {' '}
        <SvgHeart className="w-[1.1em] h-[1.1em]" />
        {' '}
        from
        {' '}
        easyphotobook.me
      </div>
    </div>
  )
}
