import { graphql } from '@api/gql/gql'

export const swapAlbumPage = graphql(`
    mutation swapAlbumPage($albumPageId: ID!, $direction: Boolean!) {
        swap_album_page(album_page_id: $albumPageId, direction: $direction) {
            id
            pages {
                id
                type
                sort
            }
        }
    }
`)
