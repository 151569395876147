import { FC } from 'react'
import { AlbumImage, CoverType } from '@api/gql/graphql'
import { clsx } from 'clsx'

type Props = {
  image: AlbumImage;
  coverType?: CoverType | null;
};

export const AlbumCoverImage: FC<Props> = (props) => (
  <img
    className={clsx(
      'w-full object-cover object-center',
      props.coverType === CoverType.Cover_8x1 ? 'h-full' : 'aspect-square',
    )}
    src={props.image.image_medium_url ?? ''}
    alt=""
    data-id={props.image.id}
    data-external-id={props.image.external_id}
    data-sort={props.image.sort}
  />
)
