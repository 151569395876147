import {
  FC, useMemo,
} from 'react'
import { Album } from '@api/gql/graphql'
import {
  defaultCoverType,
  parseCoverSizes,
} from '@constants/album'
import { ModalType } from '@modals/_types'
import { useModal } from '@modals/core/use-modal'
import { clsx } from 'clsx'

import { getCoverContainerClass, getCoverTypeClass } from '../constants'

import { AlbumCoverImage } from './album-cover-image'

type Props = {
  album: Album
}

export const AlbumCover: FC<Props> = (props) => {
  const { openModal } = useModal()

  const images = useMemo(() => {
    if (!props.album.pages?.length) {
      return []
    }

    const { count } = parseCoverSizes(props.album.cover_type || defaultCoverType)

    return (props.album.pages[0].images?.filter((image) => !image.deleted_at) ?? []).slice(0, count)
  }, [props.album])

  const onOpenSelectAlbumCover = () => {
    openModal({
      type: ModalType.SELECT_ALBUM_COVER_MODAL,
      album_id: props.album.id,
    })
  }

  return (
    <div
      className="flex h-full flex-col justify-between cursor-pointer"
      onClick={onOpenSelectAlbumCover}
    >
      <div
        className={clsx(
          'flex flex-col h-full mb-2 md:mb-4',
          getCoverContainerClass(props.album.cover_type || defaultCoverType),
        )}
      >
        <div className="mb-3 md:mb-6">
          <div className="text-[8em] md:text-[4em] font-[500] leading-none mb-0.5 md:mb-1">
            {props.album.title}
          </div>

          <div className="text-[6em] md:text-[3em] leading-none">{props.album.subtitle}</div>
        </div>

        <div
          key={props.album.cover_type}
          className={clsx(
            'grid gap-1',
            getCoverTypeClass(props.album.cover_type || defaultCoverType),
          )}
        >
          {images.map((image) => (
            <AlbumCoverImage
              key={image.id}
              image={image}
              coverType={props.album.cover_type}
            />
          ))}
        </div>
      </div>

      <div className="flex items-end text-[3.4em] md:text-[1.5em] leading-none text-gray-400">
        {props.album.creator_name}
      </div>
    </div>
  )
}
