import {
  FC,
  ImgHTMLAttributes,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from 'react'

type Props = {
  debug?: boolean
}

export const Image: FC<ImgHTMLAttributes<any> & Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  const handleError = useCallback((e: SyntheticEvent<HTMLImageElement, Event>) => {
    setLoading(false)
    setError(`Failed to load image: ${e.currentTarget.src}`)
  }, [])

  const handleLoad = useCallback(() => {
    setLoading(false)
  }, [])

  const tagProps = useMemo(() => ({ ...props, debug: undefined }), [props])

  return (
    <>
      {props.debug ? (
        <>
          {loading && 'Loading image'}
          {(!!error && !loading) && error}

          <img
            {...tagProps}
            alt={props.alt}
            onError={handleError}
            onLoad={handleLoad}
          />
        </>
      ) : (
        <img
          {...tagProps}
          alt={props.alt}
          onError={handleError}
          onLoad={handleLoad}
        />
      )}
    </>
  )
}
