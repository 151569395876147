import { graphql } from '@api/gql/gql'

export const subscriptionOnTask = graphql(`
    subscription subscriptionOnTask($taskType: TaskType!, $input: TaskTargetInput!) {
        subscribe_on_task(task_type: $taskType, input: $input) {
            job_id
            status
            steps
            type
        }
    }
`)
