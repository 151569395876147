import {
  CSSProperties, FC, useContext, useMemo, useState,
} from 'react'
import { AlbumImage, ImageSize, Role } from '@api/gql/graphql'
import { getProfile } from '@api/schemas'
import { useQuery } from '@apollo/client'
import { Image } from '@components/image'
import { useIsMobile } from '@nextui-org/use-is-mobile'
import { Control } from '@share/album/components/control'
import { WindowSizesContext } from '@share/album/context'
import { clsx } from 'clsx'

type Props = {
  albumId: string
  image: AlbumImage;
  pageWidth: number;
  pageHeight: number;
  imageSize: ImageSize;
  debug?: boolean;
  isControl?: boolean;
  isWide?: boolean
};

export const AlbumPicture: FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const isMobile = useIsMobile()
  const windowSizes = useContext(WindowSizesContext)
  const profileState = useQuery(getProfile, { fetchPolicy: 'cache-only' })

  const inner = props.isWide ? windowSizes.inner : windowSizes.innerHalf

  const getPercent = () => {
    if (props.pageWidth > props.pageHeight) {
      return (inner.width / props.pageWidth) * 100
    }

    return (inner.height / props.pageHeight) * 100
  }

  const resize = (num: number): number => {
    const PERCENT = 100 - getPercent()
    return Math.floor(num * (1 - PERCENT / 100))
  }

  const getOffsetPositionY = () => {
    if (props.pageWidth > props.pageHeight) {
      const height = resize(props.pageHeight || 0)
      return (inner.height - height) / 2
    }

    return 0
  }

  const getOffsetPositionX = () => {
    if (props.pageWidth < props.pageHeight) {
      const width = resize(props.pageWidth || 0)
      return (inner.width - width) / 2
    }

    return 0
  }

  const imageUrl = useMemo(() => {
    switch (props.imageSize) {
      case ImageSize.Small:
        return props.image.image_small_url
      case ImageSize.Large:
        return props.image.image_large_url
      case ImageSize.Medium:
        return props.image.image_medium_url
      default:
        return props.image.image_medium_url
    }
  }, [props.image])

  const imgStyle = useMemo((): CSSProperties => ({
    width: resize(props.image.width || 0),
    height: resize(props.image.height || 0),
    top: resize(props.image.position_y || 0) + getOffsetPositionY(),
    left: resize(props.image.position_x || 0) + getOffsetPositionX(),
  }), [props.image, windowSizes, props.pageWidth, props.pageHeight])

  const isShowDuplicate = profileState.data?.profile.role !== Role.Client

  return (
    <div
      key={props.image.external_id}
      data-id={props.image.id}
      data-external-id={props.image.external_id}
      data-sort={props.image.sort}
      className={clsx(
        'absolute border-[1px] md:border-[2px] box-border overflow-hidden',
        props.image.duplicate && isShowDuplicate && 'opacity-40',
        'border border-transparent hover:border-primary md:hover:border-transparent transition-colors cursor-pointer md:cursor-auto group',
      )}
      style={imgStyle}
      onClick={() => {
        if (isMobile) {
          setIsOpen(!isOpen)
        }
      }}
    >
      <Image
        src={imageUrl ?? ''}
        alt=""
        className="object-cover"
        style={imgStyle}
        debug={props.debug}
      />

      {props.isControl && (
        <Control
          isOpen={isOpen}
          albumId={props.albumId}
          image={props.image}
          onClose={() => setIsOpen(false)}
          editable={!isMobile}
        />
      )}
    </div>
  )
}
