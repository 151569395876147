import {
  useEffect, useMemo, useRef, useState,
} from 'react'
import { useIsMobile } from '@nextui-org/use-is-mobile'
import { WindowSizes } from '@share/album/types'

type RootSize = {
  width: number
  height: number
}

type Props = {
  defaultSize?: number
}

export const useWindowSizes = (props?: Props) => {
  const isMobile = useIsMobile()

  const [rootSize, setRootSize] = useState<RootSize>({ width: 0, height: 0 })
  const ref = useRef<HTMLDivElement | null>(null)

  const windowSizes = useMemo((): WindowSizes => {
    const gapOffset = isMobile ? 0 : 10
    const padding = isMobile ? 6 : 16

    const size = props?.defaultSize ?? 2

    const outerHalfSize = rootSize.width / size
    const innerHalfSize = (rootSize.width / size - padding * 2)

    const outer = rootSize.width
    const inner = (rootSize.width - padding * 2)

    return {
      outer: {
        width: outer,
        height: outer,
      },
      inner: {
        width: inner,
        height: inner,
      },
      outerHalf: {
        width: outerHalfSize - gapOffset,
        height: outerHalfSize,
      },
      innerHalf: {
        width: innerHalfSize - gapOffset,
        height: innerHalfSize,
      },
      padding: {
        left: padding,
        right: padding,
        bottom: padding,
        top: padding,
      },
    }
  }, [rootSize, isMobile])

  const handleResize = () => {
    if (!ref.current) {
      return
    }

    setRootSize({
      width: ref.current?.clientWidth ?? 0,
      height: ref.current?.clientHeight ?? 0,
    })
  }

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize)
    if (ref.current) {
      resizeObserver.observe(ref.current)
    }

    return () => resizeObserver.disconnect()
  }, [])

  return {
    ...windowSizes,
    ref,
  }
}
