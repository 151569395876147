import { graphql } from '@api/gql/gql'

export const deleteAlbumImage = graphql(`
    mutation deleteAlbumImage($albumImageId: ID!) {
        delete_album_image(album_image_id: $albumImageId) {
            id
            sort
            width
            height
            type
            images {
                id
                album_page_id
                external_id
                image_small_url
                image_medium_url
                image_large_url
                width
                height
                position_x
                position_y
                sort
                duplicate
                updated_at
                created_at
                deleted_at
            }
        }
    }
`)
